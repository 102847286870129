import React, { useState } from "react"
import { Link } from "gatsby"
import footerLogo from "../images/logos/Autos2050_Logo_Color_KO_Tagline.png"
import ActualSize from "../images/logos/AS-web-design-bug.png"
import BlueButton from "./blueButton"

import Modal from "../components/modal"
import NewsForm from "../components/newsForm"
import { AnimatePresence } from "framer-motion"

function Footer() {
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <>
      <AnimatePresence exitBeforeEnter>
        {modalOpen && (
          <Modal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            key="News Form"
          >
            <NewsForm />
          </Modal>
        )}
      </AnimatePresence>
      <footer className="w-full bg-black">
        <div className="container relative mx-auto px-4 py-4 text-center text-gray-500 text-xs lg:px-0 lg:w-10/12 lg:text-sm">
          <div className="flex flex-col items-center justify-between mb-8 py-8 space-y-8 md:flex-row md:space-y-0 lg:mb-16">
            <div className="grid place-items-start w-3/5 lg:w-auto">
              <Link to="/">
                <img className="w-80" src={footerLogo} alt="Footer Logo" />
              </Link>
            </div>
            <div className="grid place-items-center">
              <BlueButton onClick={() => setModalOpen(true)}>
                SIGN UP FOR NEWS
              </BlueButton>
            </div>
          </div>
          <a href="https://actualsize.com/" target="_blank" rel="noreferrer">
            <img
              className="mx-auto pb-2 w-40 lg:absolute lg:bottom-0 lg:right-0 lg:pb-4"
              src={ActualSize}
              alt="Design by Actual Size"
            />
          </a>
          © {new Date().getFullYear()} Autos2050®. All Rights Reserved.
        </div>
      </footer>
    </>
  )
}

export default Footer
