import React, { useState } from "react"
import { graphql, Link, StaticQuery } from "gatsby"

import innovationLogo from "../images/logos/Alliance_Autos_lockup.png"
import autos2050Logo from "../images/logos/Alliance_Autos_2050_lockup.png"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import {
  faBars,
  faTimes,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons"

function Header({ siteTitle }) {
  const [nav, setNav] = useState(false)
  const [programNav, setProgramNav] = useState(false)

  return (
    <StaticQuery
      query={graphql`
        query HeaderQuery {
          virtualPremier: sanityVirtualPremierEvent {
            title
          }
          futureDriven: sanityFutureDrivenWebinar {
            title
          }
          overtake: sanityOvertakePodcast {
            title
          }
        }
      `}
      render={data => (
        <header
          className={`mx-auto w-full px-8 py-4 shadow-md md:p-0 md:py-6 fixed bg-white z-50`}
        >
          <div className="container mx-auto md:flex md:items-center md:justify-between md:text-center lg:w-10/12">
            <div className="relative flex items-center justify-between mx-auto text-center md:block md:mx-0">
              <div className="flex space-x-4">
                <a
                  href="https://www.autosinnovate.org/"
                  className="outline-none focus:outline-none"
                >
                  <img
                    className="mx-auto w-32 lg:mx-0 lg:w-40"
                    src={innovationLogo}
                    alt="Alliance for Automotive Innovation Logo"
                  />
                </a>
                <Link to="/">
                  <img
                    className="mx-auto w-24 lg:mx-0 lg:w-32"
                    src={autos2050Logo}
                    alt="Autos2050 Logo"
                  />
                </Link>
              </div>
              <button
                className={`cursor-pointer md:hidden z-40 focus:outline-none
      `}
                onClick={() => setNav(!nav)}
                aria-label="Home"
              >
                {nav ? (
                  <FontAwesomeIcon icon={faTimes} className="text-xl" />
                ) : (
                  <FontAwesomeIcon icon={faBars} className="text-xl" />
                )}
              </button>
            </div>
            <nav
              className={`transition-all relative bg-white max-h-0 opacity-0 pointer-events-none ${
                nav
                  ? "max-h-96 opacity-100 px-4 pb-4 pt-8 pointer-events-auto"
                  : ""
              } md:max-h-96 md:bg-none md:opacity-100 md:pointer-events-auto md:px-0 md:pb-0 md:pt-0`}
            >
              <ul
                className={`flex flex-col justify-center items-center space-y-4 md:flex md:flex-row md:block md:items-center md:space-x-8 md:space-y-0`}
              >
                <div className="group">
                  <div className="flex items-center justify-center md:block">
                    <Link to="/programs">
                      <li
                        className={`font-bold mr-4 ${
                          siteTitle === "programs"
                            ? "text-primary"
                            : "text-gray-600"
                        }  text-lg hover:text-primary md:mr-0`}
                      >
                        Programs
                      </li>
                    </Link>
                    <button
                      className={`cursor-pointer md:hidden z-40 focus:outline-none -mb-1`}
                      onClick={() => setProgramNav(!programNav)}
                    >
                      {programNav ? (
                        <FontAwesomeIcon
                          icon={faChevronUp}
                          className="text-xl"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className="text-xl"
                        />
                      )}
                    </button>
                  </div>

                  <div
                    className={`max-h-0 opacity-0 pointer-events-none transition-all ${
                      programNav
                        ? "max-h-96 opacity-100 pointer-events-auto"
                        : ""
                    } md:absolute md:opacity-100 md:max-h-96 md:pointer-events-auto bg-white md:p-7 md:-left-7 md:hidden group-hover:block`}
                  >
                    <ul className="flex flex-col text-left text-gray-600 font-bold leading-8">
                      <Link to="/past-events/summit-2021">
                        <li className="hover:text-primary">
                          {/* {data.virtualPremier.title} */}
                          Summit 2021
                        </li>
                      </Link>
                      <Link to="/programs/future-driven">
                        <li className="hover:text-primary">
                          {data.futureDriven.title}
                        </li>
                      </Link>
                      <Link to="/programs/overtake">
                        <li className="hover:text-primary">
                          {data.overtake.title}
                        </li>
                      </Link>
                    </ul>
                  </div>
                </div>
                <Link to="/sponsorship">
                  <li
                    className={`font-bold ${
                      siteTitle === "sponsorship"
                        ? "text-primary"
                        : "text-gray-600"
                    }  text-lg hover:text-primary`}
                  >
                    Sponsorship
                  </li>
                </Link>

                <Link to="/awards">
                  <li
                    className={`font-bold ${
                      siteTitle === "awards" ? "text-primary" : "text-gray-600"
                    }  text-lg hover:text-primary`}
                  >
                    Awards
                  </li>
                </Link>

                <Link to="/resources">
                  <li
                    className={`font-bold ${
                      siteTitle === "resources"
                        ? "text-primary"
                        : "text-gray-600"
                    }  text-lg hover:text-primary`}
                  >
                    Resources
                  </li>
                </Link>
              </ul>
            </nav>
          </div>
        </header>
      )}
    />
  )
}

export default Header
