/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { AnimatePresence } from "framer-motion"
import Header from "./header"
import Footer from "./footer"

const Layout = ({ children, title, noPadding }) => {
  return (
    <AnimatePresence exitBeforeEnter>
      <Header siteTitle={title} key={title + "Header"} />
      <main key={title} className={noPadding ? "" : "pt-8"}>
        {children}
      </main>
      <Footer key={title + "Footer"} />
    </AnimatePresence>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
