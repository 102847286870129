import React from "react"

function BlueButton({ children, onClick }) {
  return (
    <button
      className="bg-secondary-200 text-sm lg:text-base text-white tracking-wide font-bold rounded-full py-2 px-4 lg:px-8 transition focus:outline-none hover:bg-opacity-75"
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export default BlueButton
